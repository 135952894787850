<template>
  <div v-if="showFlag">
    <section class="modalTurn">
      <div class="modalTurn-content">
        <div class="modalWin">
          <h3 v-if="job.id">Карточка должности</h3>
          <h3 v-else>Добавить должность</h3>

          <table style="width: 90%">
            <tr>
              <td class="R">Название</td>
              <td class="L">
                <input type="text" v-model.trim="job.job_name" ref="job_name" />
              </td>
            </tr>

            <tr>
              <td class="R">Ставка</td>
              <td class="L"><input type="number" v-model.number="job.job_rate" /></td>
            </tr>

            <tr>
              <td class="R">Значок</td>
              <td class="L"><input type="text" v-model.trim="job.job_icon" /></td>
            </tr>
          </table>

          <div class="err">{{ errMessage }}&nbsp;</div>
          <br />
          <div class="col-revers">
            <button ref="applyButton" v-on:click.prevent="Apply()" class="btn">
              {{ job.id ? "Применить" : "Добавить" }}
            </button>
            &nbsp;&nbsp;&nbsp;&nbsp;
            <button v-on:click.prevent="Cancel()" class="btn cancel">Отмена</button>
            &nbsp;&nbsp;&nbsp;&nbsp;
            <button v-if="job.id" v-on:click.prevent="Delete()" class="btn warn">
              Удалить
            </button>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>
<style lang="scss" scoped>
div {
  padding-top: 5px;
  padding-bottom: 5px;
  vertical-align: middle;
}
.col-revers {
  display: flex;
  flex-direction: row-reverse;
}
td.R {
  text-align: right;
}
td.L {
  text-align: left;
}
table {
  border-spacing: 0.4rem;
}
</style>
<script>
import { request } from "@/components-js/requestSrv";

export default {
  name: "JobWindow",
  props: {
    showFlag: Boolean,
    jobIn: {},
  },

  data() {
    return {
      errMessage: null,
      job: {},
    };
  },

  watch: {
    showFlag: function(newValue) {
      // window appeared
      if (newValue) {
        document.addEventListener("keyup", this.keyPressHandler);

        if (this.jobIn) {
          // create copy
          for (let i in this.jobIn) {
            this.job[i] = this.jobIn[i];
          }
        } else {
          this.job = new Object();
          this.initDefault();
        }
      } else document.removeEventListener("keyup", this.keyPressHandler);
    },
  },

  methods: {
    initDefault() {
      this.job.job_name = "Должность";
      this.job.job_rate = 100;
      this.job.job_icon = null;
    },

    setType(ref, type) {
      this.$refs[ref].setAttribute("type", type);
    },

    keyPressHandler(evt) {
      if (evt.code == "Escape") this.Cancel();
    },

    async Apply() {
      if (!this.job.job_name.length) {
        this.$refs.job_name.classList.add("warn-border");
        setTimeout(() => this.$refs.job_name.classList.remove("warn-border"), 300);
        return;
      }

      if (this.job.id) {
        const res = await request("/api/job/update", "POST", this.job);
        if (res.affectedRows) this.windowClose();
        else {
          this.$refs.applyButton.classList.add("warn");
          setTimeout(() => this.$refs.applyButton.classList.remove("warn"), 300);
          return;
        }
      } else {
        const check = await request("/api/job/checkunique", "POST", {
          job_name: this.job.job_name,
        });
        if (check[0].cnt) {
          this.errMessage = "Название уже используется";
          setTimeout(() => (this.errMessage = null), 1500);
          return;
        }
        const res = await request("/api/job/insert", "POST", this.job);
        if (res.affectedRows == 1 && res.insertId) {
          this.job.id = res.insertId;
          this.windowClose();
        }
      }
    },

    async Delete() {
      if (!confirm("Удалить должность?")) return;
      let res = await request("/api/job/deletecheck", "POST", { id: this.job.id });
      if (parseInt(res.cnt)) {
        this.errMessage = "Смен и/или людей с участием должности: " + res.cnt;
        setTimeout(() => (this.errMessage = null), 1500);
        return;
      }

      res = await request("/api/job/delete", "DELETE", {
        id: this.job.id,
      });
      if (res.affectedRows) {
        this.job.id *= -1;
        this.windowClose();
      } else {
        this.errMessage = "ошибка удаления";
        setTimeout(() => (this.errMessage = null), 1500);
      }
    },

    Cancel() {
      this.job.id = 0;
      this.windowClose();
    },

    windowClose() {
      this.$emit("hide", this.job);
    },
  },
};
</script>
